import React from "react";
import logo from '../../assets/images/banner/fabricated_banner.png'
const index = ()=>{
    return (
        <div>
            <div className="cooling-banner">
                <h1 className="banner-title-fab">Cooling Unit</h1>
            </div>
        </div>
    )
}

export default index;