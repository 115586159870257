import React from "react";

const Index = ()=>{
    
    return(
        <div>
            <div className="cooling-details">
                <h1 className="banner-title-fab">Cooling Unit</h1>
            </div>
        </div>
    )
}

export default Index;