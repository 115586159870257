export default{
    subcategoryList:{
        loading:false,
        error:null,
        data:null
    },
    modalListdata:{
        modal_loading:false,
        modal_error:null,
        modal_data:null
    }
}