import React from "react";

const Index = ()=>{
    return(
        <div>
            <div className="insulation-banner">
                <h1 className="banner-title-fab" style={{color:'#1B5174'}}>Insulation Panel</h1>
            </div>
        </div>
    )
}

export default Index;